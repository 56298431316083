@mixin submenu-container {
  @extend .border-radius;
  @extend .box-shadow-small;
  overflow: auto;
  background-color: $white;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin submenu {
  display: flex;
  list-style: none;
  border-bottom: 1px solid $light-blue-gray;
  padding: 0 $space-s;
  justify-content: space-around;
}

@mixin submenu-item {
  padding: $space-m 0;
  color: $gray-light;
  font-weight: 600;
  position: relative;
  white-space: nowrap;

  &:hover,
  &.active {
    color: $gray-darker;
    text-decoration: none;
  }

  &.active:after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: $red;
    position: absolute;
    bottom: $space-s;
    left: 50%;
    margin-left: -3px;
    border-radius: 3px;
    box-shadow: 0 0 12px #e5004638;
  }
}

.submenu-component {
  margin-bottom: $space-m;

  .navigation-wrapper {
    @include submenu-container;
  }

  .navigation {
    // Fixes iOS Safari issue with flexbox justify-content: space-around inside overflow: auto
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: max-content;

    ul {
      @include submenu;

      min-width: 360px;
      width: 100%;
    }

    li {
      display: flex;

      a {
        @include submenu-item;
        color: var(--color-primary);
      }
    }
  }
}
